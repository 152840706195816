import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const TalksPage = () => (
  <Layout>
    <SEO title="Talks" />
    <h1>Recent talks</h1>
    <p>
      June, 2019 — <em>Serverless: Less is More</em> @Ethiq Software Engineering
      Speaker Series
    </p>
  </Layout>
);

export default TalksPage;
